@import url('https://fonts.googleapis.com/css?family=Roboto');


body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  min-width: 1280px;
}

* {
  font-family: "Roboto" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}


.loader {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(0, 0, 0, 0.05); */
  border-radius: 4px

}

.flex-column {
  display: flex;
  flex-direction: column;
}

/* @font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Black.ttf) format('truetype');
} */
span.content-heading {
  font-size: 56px;
    color: #fff;
    line-height: 64px;
  width: auto;
}
.content-container{
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 346px;
  left: 20px;
  transform: translate(0, -7%);
  padding: 30px 77px 30px 30px;
  background-image:linear-gradient(to right, #000000d9, #0000);
}
.content-container p{
  color:#fff;
  font-size:32px;
  margin-bottom: 0;
  padding: 0px 10px;
}
.print {
  width:40px;
  height:40px;
  display: inline-block;
  margin-right: 10px;
}
.print img{
  width:100%;
}
.ant-progress-circle-trail {
  stroke: #d1d1d1 !important;

}
.ant-progress-text
{
  font-weight: 600;
}
@media(max-width:1400px){

/* .content-container {
    margin-top: 200px;
    left: 20px;
    transform: translate(0, -13%);
    background-image: linear-gradient(to right, #000, #0000);
} */
}

