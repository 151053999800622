.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

.flex-end {
  justify-content: flex-end;
}

.left-align {
  align-items: flex-end;
}

.flex-start {
  justify-content: start;
}

.pl-24 {
  padding-left: '24px';
}

.space-between {
  justify-content: space-between;
  width: 100%;
}

.ml-24 {
  margin-left: 24px;
}

.report-card {
  background: #FAFAFA;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 20px;
  width: 100%;
  padding: 24px;
  margin-bottom: 24px;
}

.start-answering {
  background: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 24px; 
}

.w-600 {
  width: '600px'
}

.m-40 {
  margin-bottom: 40px;
}
.ant-form-item {
  margin-bottom: 17px !important;
}