@import url(https://fonts.googleapis.com/css?family=Roboto);
@import url(https://fonts.googleapis.com/css?family=Roboto);
body {
  margin: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;
  min-width: 1280px;
}

* {
  font-family: "Roboto" , -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
}


.loader {
  width: 100%;
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background: rgba(0, 0, 0, 0.05); */
  border-radius: 4px

}

.flex-column {
  display: flex;
  flex-direction: column;
}

/* @font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 900;
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Black.ttf) format('truetype');
} */
span.content-heading {
  font-size: 56px;
    color: #fff;
    line-height: 64px;
  width: auto;
}
.content-container{
  display: flex;
  flex-direction: column;
  position: absolute;
  margin-top: 346px;
  left: 20px;
  -webkit-transform: translate(0, -7%);
          transform: translate(0, -7%);
  padding: 30px 77px 30px 30px;
  background-image:linear-gradient(to right, #000000d9, #0000);
}
.content-container p{
  color:#fff;
  font-size:32px;
  margin-bottom: 0;
  padding: 0px 10px;
}
.print {
  width:40px;
  height:40px;
  display: inline-block;
  margin-right: 10px;
}
.print img{
  width:100%;
}
.ant-progress-circle-trail {
  stroke: #d1d1d1 !important;

}
.ant-progress-text
{
  font-weight: 600;
}
@media(max-width:1400px){

/* .content-container {
    margin-top: 200px;
    left: 20px;
    transform: translate(0, -13%);
    background-image: linear-gradient(to right, #000, #0000);
} */
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

/* @font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url(./fonts/Roboto/Roboto-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 500;
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'Roboto';
    font-weight: 600;
    src: local('Roboto'), url(./fonts/Roboto/Roboto-Black.ttf) format('truetype');
} */
.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.center {
  justify-content: center;
  align-items: center;
}

.flex-end {
  justify-content: flex-end;
}

.left-align {
  align-items: flex-end;
}

.flex-start {
  justify-content: start;
}

.pl-24 {
  padding-left: '24px';
}

.space-between {
  justify-content: space-between;
  width: 100%;
}

.ml-24 {
  margin-left: 24px;
}

.report-card {
  background: #FAFAFA;
  border: 1px solid #DFDFDF;
  box-sizing: border-box;
  border-radius: 20px;
  width: 100%;
  padding: 24px;
  margin-bottom: 24px;
}

.start-answering {
  background: #fff;
  border: 1px solid #fff;
  box-sizing: border-box;
  box-shadow: 0px 4px 34px rgba(0, 0, 0, 0.12);
  border-radius: 12px;
  padding: 24px; 
}

.w-600 {
  width: '600px'
}

.m-40 {
  margin-bottom: 40px;
}
.ant-form-item {
  margin-bottom: 17px !important;
}
